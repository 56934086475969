import React, { useState, useEffect, useRef } from 'react';
import './Chatbot.css';
import userAvatar from './assets/img/user-avatar.png';
import botAvatar from './assets/img/bot-avatar.png';
import logo from './assets/img/logo.png';
import stringSimilarity from 'string-similarity';


const Chatbot = () => {
  const [input, setInput] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [shouldAutoFocusInput, setShouldAutoFocusInput] = useState(true);
  const chatEndRef = useRef(null);
  const inputRef = useRef(null);

  const responses = {
    "hello": "Hello! How can I assist you today?",
    "about us": "Welcome to ROX, a leading regional company in the field of consumer goods distribution. Since 1992, when ROX was founded, we have been successfully distributing world-renowned brands and products. Our story began in Croatia, and very quickly we expanded our business to Bosnia and Herzegovina, Serbia, and Slovenia.",
    "brands": "During its many decades of existence, ROX has won the trust of more than 50 global brands, whose products it distributes throughout the region. We carefully monitor the needs of the market and believe in the excellence and quality of consumer goods that we distribute to end consumers.",
    "distribution": "ROX doo is one of the leading distributors of consumer goods in the region. From our three distribution centers (Zagreb, Orašje, Belgrade), we distribute goods from more than 70 suppliers to more than 13,000 points of sale where we are present. Our knowledge of logistics procedures, excellent knowledge of business practices for each country in the region, a coordinated team of experts and partnership relations based on mutual trust are responsible for quality and efficient service.",
    "contact": "For more information or inquiries, please contact us via email at info@rox.hr or check our contact information on our website.",
    "mission": "The satisfaction and trust of our business partners and customers are the guiding thread of our business at ROX.",
    "vision": "We aspire to be a leader in the growth of our current and future business partners, the community, and the environment in which we operate.",
    "strategy": "Our strategy is based on continuous improvement to stay one step ahead in a dynamic business environment. We focus on long-term partnerships with our customers, providing high-quality products and services that reflect our commitment to excellence.",
    "values": "At ROX, we value our community of people and encourage the professional development of each member of our team. We foster a pleasant and healthy organizational culture where every employee feels welcome and supported. We value openness, innovative ideas, and diversity of opinion.",
    "headquarters": "ROX's headquarters are located at Slavonska avenija 100, 10040 Zagreb, Croatia.",
    "why rox": "ROX is the best choice as a distributor due to our many years of experience, expertise, efficiency, and large portfolio of products. We cover a wide range of distribution activities including warehousing, logistics, inventory management, customs clearance, trade marketing, and sales. We have a developed network of partners in the region, which includes more than 5,000 wholesale and retail stores.",
    "where distribute": `ROX distributes goods in Croatia, Slovenia, Serbia, and Bosnia and Herzegovina. 
      - In Croatia: Founded in 1992, HQ at Slavonska Avenija 100, Zagreb. 175 employees, 12000 delivery locations.
      - In Slovenia: Founded in 2015, HQ at Ukmarjeva 4, Ljubljana. 8 employees, 1412 delivery locations.
      - In Serbia: Founded in 2014, HQ at Rucovićeva 7, Belgrade. 17 employees, 2300 delivery locations.
      - In Bosnia and Herzegovina: Founded in 1998, HQ at Street XIV no. 76, Orašje. 84 employees, 2000 delivery locations.`,
    "logistics": `Modern logistics at ROX involves integral logistics management, ensuring an uninterrupted flow of products from production to the end user. 
      - ROX logistics distribution center in Zagreb spans 7,000 square meters with 4,500 pallet spaces, supplying Croatia and Slovenia. 
      - Our IT systems (WMS, 3PL) offer 24-hour control and real-time inventory management, with full IT connectivity via EDI/API.`,
    "transport": "ROX Logistics offers ambient goods transport services within Croatia. We focus on quality, fast, and efficient delivery to reduce transportation costs for our partners.",
    "stock": "Our logistics distribution center in Zagreb ensures on-time fulfillment of orders across Croatia and Slovenia. We provide comprehensive warehousing, order services, and inventory management to our partners.",
    "value added services": `ROX offers various value-added services such as labeling, declaring goods, packaging, repackaging, and promo packaging services.
      - Annually, we declare and label over 25,000,000 pieces of goods, and machine over 10,000,000 promo packages.
      - For more information, contact logistika@rox.hr.`,
    "careers": `ROX is a stable and successful company, offering competitive salaries, bonuses, and awards for successful results. We value fresh ideas, a proactive approach, and innovative solutions. 
      - Explore open positions on our website or send an open application.`,
    "work at rox": `At ROX, employees can realize their full potential and personal goals. We strive to be the best employer in the region, offering professional training and development opportunities.
      - Open positions include Key Account Manager, Sourcing Expert, Market Analyst, Logistics Planner, and Delivery Vehicle Driver.`,
    "what products does rox distribute": "ROX distributes a wide range of consumer goods including food, beverages, personal care products, and household items.",
    "how long has rox been in business": "ROX was founded in 1992, making it over 30 years old.",
    "where is rox headquartered": "ROX's headquarters are located at Slavonska avenija 100, 10040 Zagreb, Croatia.",
    "can you tell me about rox's mission": "The satisfaction and trust of our business partners and customers are the guiding thread of our business at ROX.",
    "what is rox's vision for the future": "We aspire to be a leader in the growth of our current and future business partners, the community, and the environment in which we operate.",
    "what are rox's core values": "At ROX, we value our community of people and encourage the professional development of each member of our team. We foster a pleasant and healthy organizational culture where every employee feels welcome and supported. We value openness, innovative ideas, and diversity of opinion.",
    "how many countries does rox operate in": "ROX operates in Croatia, Slovenia, Serbia, and Bosnia and Herzegovina.",
    "what brands does rox work with": "ROX works with over 50 global brands in its distribution network.",
    "what are rox's main distribution centers": "ROX's main distribution centers are located in Zagreb, Orašje, and Belgrade.",
    "how can i contact rox for more information": "For more information or inquiries, please contact us via email at info@rox.hr or check our contact information on our website.",
    "what is rox's strategy for growth": "Our strategy is based on continuous improvement to stay one step ahead in a dynamic business environment. We focus on long-term partnerships with our customers, providing high-quality products and services that reflect our commitment to excellence.",
    "what are rox's competitive advantages": "ROX stands out due to its many years of experience, expertise, efficiency, and extensive portfolio of products. We offer comprehensive distribution services including warehousing, logistics, inventory management, customs clearance, trade marketing, and sales.",
    "how does rox ensure product quality": "ROX ensures product quality through rigorous quality control measures and by partnering with trusted global brands.",
    "what services does rox offer besides distribution": "Besides distribution, ROX offers value-added services such as labeling, declaring goods, packaging, repackaging, and promo packaging services.",
    "where does rox distribute its products": "ROX distributes its products in Croatia, Slovenia, Serbia, and Bosnia and Herzegovina through a network of over 13,000 points of sale.",
    "how does rox manage its supply chain": "ROX manages its supply chain with modern logistics practices, including real-time inventory management and efficient transport services.",
    "what technologies does rox use in logistics": "ROX uses advanced IT systems like WMS (Warehouse Management System) and 3PL (Third Party Logistics) for 24-hour control and real-time inventory management.",
    "what are rox's policies on environmental responsibility": "ROX is committed to environmental responsibility through sustainable practices and compliance with environmental regulations.",
    "what certifications does rox hold": "ROX holds certifications that ensure compliance with industry standards and regulations.",
    "how does rox support local communities": "ROX supports local communities through various corporate social responsibility initiatives.",
    "what are rox's plans for expansion": "ROX plans to expand its operations and strengthen its presence in the region.",
    "how does rox handle crisis management": "ROX has a robust crisis management plan in place to handle unforeseen events and ensure business continuity.",
    "what are the qualifications required to work at rox": "Qualifications required to work at ROX vary depending on the position, but generally include relevant education and experience in the field.",
    "does rox offer internships or apprenticeships": "Yes, ROX offers internships and apprenticeships to support young talent in the industry.",
    "what are rox's core departments": "ROX's core departments include logistics, sales, marketing, finance, and human resources.",
    "how does rox promote its products to consumers": "ROX promotes its products through strategic marketing campaigns and trade marketing activities.",
    "what are the upcoming events or promotions by rox": "For information on upcoming events or promotions by ROX, please visit our website or follow us on social media.",
    "default": "I'm sorry, I didn't understand that. Could you please rephrase your question or ask something else?"
  };

  const processUserInput = (input) => {
    const lowercaseInput = input.toLowerCase().trim();

    const bestMatch = stringSimilarity.findBestMatch(lowercaseInput, Object.keys(responses));
    const bestResponseKey = bestMatch.bestMatch.target;

    if (bestMatch.bestMatch.rating < 0.2) {
      return responses["default"];
    }

    return responses[bestResponseKey] || responses["default"];
  };

  const wrapTextInSpans = (text, direction) => {
    return text.split('').map((char, index) => (
      <span key={index} style={{ '--animation-order': direction === 'left-to-right' ? index : text.length - index }}>
        {char === ' ' ? '\u00A0' : char}
      </span>
    ));
  };

  const handleSendMessage = () => {
    if (input.trim() === '' || isBotTyping) return;

    const userMessage = { text: input, sender: 'user', profile: userAvatar };
    setChatHistory(prev => [...prev, userMessage]);

    setIsBotTyping(true);

    setTimeout(() => {
      const botResponse = { text: processUserInput(input), sender: 'bot', profile: botAvatar };
      setChatHistory(prev => [...prev, botResponse]);
      setIsBotTyping(false);

      if (shouldAutoFocusInput) {
        inputRef.current.focus();
      }
    }, 900);

    setInput('');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory]);

  useEffect(() => {
    if (!isBotTyping && shouldAutoFocusInput) {
      inputRef.current.focus();
    }
  }, [isBotTyping, shouldAutoFocusInput]);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;
      setShouldAutoFocusInput(!isMobile);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      <div className="logo-container">
        <img src={logo} alt="Logo" />
      </div>
      <div className="chatbot">
        <div className="chat-history">
          {chatHistory.map((message, index) => (
            <div key={index} className={`message ${message.sender}`}>
              <div className="message-avatar">
                <img src={message.profile} alt="Avatar" className="avatar" />
              </div>
              <div className={`message-text ${message.sender === 'user' ? 'right-to-left' : 'left-to-right'}`}>
                {wrapTextInSpans(message.text, message.sender === 'user' ? 'right-to-left' : 'left-to-right')}
              </div>
            </div>
          ))}
          {isBotTyping && (
            <div className="message bot typing">
              <span className="dot-animation"></span>
              <span className="dot-animation"></span>
              <span className="dot-animation"></span>
            </div>
          )}
          <div ref={chatEndRef}></div>
        </div>
        <div className="chat-input">
          <input
            ref={inputRef}
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder={isBotTyping ? "Bot is typing..." : "Type your message..."}
            disabled={isBotTyping}
          />
          <button onClick={handleSendMessage} disabled={isBotTyping}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;